import axios from 'axios';

const axiosIntance = axios.create({
  // baseURL: 'http://localhost:1337/api/v1',
  baseURL: "https://api-tool.tholaptrinh.com/api/v1",
  headers: {
    'Content-type': 'application/json',
  },
});

axiosIntance.interceptors.request.use((config) => {
  config.headers = config.headers ?? {};
  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  return config;
});

export default axiosIntance;