// @mui

import { useEffect, useState } from 'react';
import { me } from 'src/services/user';

// ----------------------------------------------------------------------

export default function useAccount() {
  const [account, setAccount] = useState({});

  useEffect(() => {
    me().then((res) => {
      setAccount({
        ...(res.data || {}),
      });
      if (res.data.token) {
        localStorage.setItem('token', res.data.token);
      }
    });
  }, []);

  return { account, setAccount };
}
