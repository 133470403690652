// @mui
import PropTypes from 'prop-types';
import { Card, Typography, CardHeader, CardContent } from '@mui/material';
import { Timeline, TimelineDot, TimelineItem, TimelineContent, TimelineSeparator, TimelineConnector } from '@mui/lab';
// utils
import moment from 'moment';
import { Breathing } from 'react-shimmer';

// ----------------------------------------------------------------------

AppOrderTimeline.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function AppOrderTimeline({ title, subheader, list, loading, ...other }) {
  return (
    <Card {...other}>
      {loading ? (
        <Breathing height={485} />
      ) : (
        <>
          <CardHeader title={title} subheader={subheader} />

          <CardContent
            sx={{
              '& .MuiTimelineItem-missingOppositeContent:before': {
                display: 'none',
              },
              minHeight: '435px'
            }}
          >
            <Timeline>
              {list.map((item, index) => (
                <OrderItem key={item.id} item={item} isLast={index === list.length - 1} />
              ))}
              {!list.length ? <h4 style={{ textAlign: 'center', opacity: 0.7 }}>Không có hoạt động nào</h4> : null}
            </Timeline>
          </CardContent>
        </>
      )}
    </Card>
  );
}

// ----------------------------------------------------------------------

OrderItem.propTypes = {
  isLast: PropTypes.bool,
  item: PropTypes.shape({
    time: PropTypes.instanceOf(Date),
    title: PropTypes.string,
    type: PropTypes.string,
  }),
};

function OrderItem({ item, isLast }) {
  const { type, title, time } = item;
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          color={
            (type === 'post' && 'info') ||
            (type === 'comment' && 'info') ||
            (type === 'note' && 'primary') ||
            (type === 'needed' && 'success') ||
            (type === 'unneeded' && 'warning') ||
            (type === 'is-sale' && 'error') ||
            'error'
          }
        />
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>

      <TimelineContent>
        <Typography variant="subtitle2">{title}</Typography>

        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {moment(time).fromNow()}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}
