// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  ...(window.location.href.includes('admin')
    ? [
        {
          title: 'Đối tác',
          path: '/dashboard',
          icon: icon('ic_cart'),
        },
      ]
    : [
        {
          title: 'Tổng quan',
          path: '/dashboard/app',
          icon: icon('ic_analytics'),
        },
        {
          title: 'Tất Cả Khách hàng',
          path: '/dashboard/user',
          icon: icon('ic_user'),
        },
        {
          title: 'Khách hàng Chưa Xử Lý',
          path: '/dashboard/user-uncheck',
          icon: icon('ic_user_uncheck'),
        },
        {
          title: 'Khách hàng có nhu cầu',
          path: '/dashboard/user-needed',
          icon: icon('ic_user_needed'),
        },
      ]),
  ...(window.location.href.includes('localhost')
    ? [
        {
          title: 'Đối tác',
          path: '/dashboard/agents',
          icon: icon('ic_cart'),
        },
      ]
    : []),
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
