import { useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import {
  Button,
  Card,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { faker } from '@faker-js/faker';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { AppOrderTimeline } from 'src/sections/@dashboard/app';
import { createActivity, getActivity, updateContact, users } from 'src/services/user';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import { exportUsers } from 'src/utils/exportExcel';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { alpha } from '@mui/material/styles';
import Label from 'src/components/label';
import useAccount from 'src/hooks/useAccount';
import { fullNameShort } from 'src/utils/user';
// mock
// ----------------------------------------------------------------------
const allAsignee = { id: 'all', fullName: 'Tất cả' };
const TABLE_HEAD = [
  { id: 'id', width: 70 },
  { id: 'displayName', label: 'Khách hàng', alignRight: false, width: 160 },
  { id: 'postContent', label: 'Nội dung', alignRight: false },
  { id: 'phoneNumber', label: 'Số điện thoại', alignRight: true, width: 140 },
];

export default function UserPage() {
  const [isNotFound, setIsNotFound] = useState(false);
  const [noteView, setNoteView] = useState();
  const [listUsers, setListUsers] = useState([]);
  const { account } = useAccount();
  const [listAssignee, setListAssignee] = useState([]);
  const [assigneeSelected, selectAssignee] = useState(allAsignee.id);
  const [activities, setActivities] = useState([]);
  const location = useLocation();
  const [filterOption, setFilterOption] = useState({
    phoneOnly: false,
    comment: false,
    post: false,
  });
  const theme = useTheme();
  const input = useRef();

  const params = useMemo(() => {
    let content = 'all';
    if (filterOption.comment && filterOption.post) {
    } else if (filterOption.comment) {
      content = 'comment';
    } else if (filterOption.post) {
      content = 'post';
    }
    return `?phoneOnly=${filterOption.phoneOnly ? 1 : 0}&content=${content}&needed=${
      location.pathname.includes('user-needed') ? 1 : 0
    }&checked=${location.pathname.includes('user-uncheck') ? 2 : 0}&limit=${
      location.pathname.includes('user-needed') ? 0 : 1000
    }`;
  }, [filterOption, location.pathname]);

  useEffect(() => {
    reloadData();
    const interval = setInterval(() => {
      reloadData();
    }, 5000);
    return () => clearInterval(interval);
  }, [params]);

  const reloadData = () => {
    users(params).then((res) => {
      let assignees = {};
      setListUsers(
        res?.data.facebookUsers?.map((user) => {
          if (user.assignee?.id) {
            assignees[user.assignee?.id] = {
              ...user.assignee,
              fullName: fullNameShort(user.assignee.fullName),
            };
          }
          if (user.checkBy?.id) {
            user.checkBy.fullName = fullNameShort(user.checkBy.fullName);
          }
          return user;
        })
      );
      setListAssignee(Object.keys(assignees).map((key) => assignees[key]));
    });
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filterName, setFilterName] = useState('');

  const usersFiltered = useMemo(() => {
    if (!filterName) {
      if (assigneeSelected !== 'all') {
        return listUsers.filter((item) => item.assignee?.id === assigneeSelected);
      }
      return listUsers;
    } else {
      return listUsers.filter(
        ({ phoneNumber, displayName, postContent, commentContent, assignee }) =>
          (assigneeSelected === 'all' || assignee?.id === assigneeSelected) &&
          `${phoneNumber}, ${displayName}, ${postContent}, ${commentContent}`
            .toLowerCase()
            .includes(filterName.toLowerCase())
      );
    }
  }, [filterName, listUsers, assigneeSelected]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const exportExcel = () => {
    exportUsers(listUsers, 'Khách Hàng-icontacs.net.xlsx');
  };

  useEffect(() => {
    handleLoadActivity();
  }, [noteView?.id]);

  const handleLoadActivity = () => {
    if (!noteView?.id) {
      setActivities([]);
      return;
    }
    getActivity(`?userId=${noteView?.id}`).then(({ data = [] }) => {
      console.log(data);
      setActivities(
        data.map(({ id, content, createDate, contentType }) => ({
          id,
          title: content,
          type: contentType,
          time: createDate,
        }))
      );
    });
  };
  const handleAddActivity = (event) => {
    createActivity({
      data: {
        userId: noteView.id,
        content: input.current?.value,
        contentType: 'note',
      },
    }).then(() => {
      input.current.value = '';
      handleLoadActivity();
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const onFilterOption = (data) => {
    setFilterOption({
      ...filterOption,
      ...data,
    });
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listUsers.length) : 0;

  return (
    <>
      <Helmet>
        <title> User | iContacts </title>
      </Helmet>

      <Container>
        <Card>
          {account?.isSuperAdmin && listAssignee.length ? (
            <Tabs
              value={assigneeSelected}
              variant="scrollable"
              onChange={(_, newValue) => {
                selectAssignee(newValue);
              }}
              sx={{
                maxWidth: 'calc(100vw - 30px)',
              }}
            >
              {[allAsignee, ...listAssignee].map((tab, index) => (
                <Tab
                  key={tab.id}
                  iconPosition="end"
                  value={tab.id}
                  sx={{
                    boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                    px: 2,
                    py: 0,
                    mr: '0 !important',
                    pointerEvents: 'none',
                    minHeight: 50,
                  }}
                  label={
                    <>
                      <Typography sx={{ pointerEvents: 'all' }} onClick={() => {}}>
                        {tab.fullName}
                      </Typography>
                    </>
                  }
                  icon={
                    <Label
                      variant={assigneeSelected === tab.id ? 'filled' : 'soft'}
                      color={tab.id === 'all' ? 'primary' : 'warning'}
                    >
                      {tab.id === 'all'
                        ? listUsers.length
                        : listUsers.filter((order) => order.assignee?.id === tab.id).length}
                    </Label>
                  }
                />
              ))}
            </Tabs>
          ) : null}
          <UserListToolbar
            onFilterOption={onFilterOption}
            exportExcel={exportExcel}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead headLabel={TABLE_HEAD} rowCount={listUsers.length} />
                <TableBody>
                  {usersFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const {
                      id,
                      facebookId,
                      displayName = '',
                      commentContent = '',
                      postContent = '',
                      createDate = 0,
                      phoneNumber = '',
                      facebookUrl = '',
                      postUrl = '',
                      checkBy = {},
                      checkStatus = '',
                    } = row;

                    return (
                      <TableRow hover key={facebookId} tabIndex={-1} role="checkbox">
                        <TableCell
                          align="center"
                          scope="row"
                          width={70}
                          sx={{
                            px: 0.5,
                            py: 2,
                          }}
                        >
                          <IconButton
                            size="small"
                            color="inherit"
                            onClick={() => {
                              if (checkStatus != 'needed') {
                                setListUsers(
                                  listUsers.map((item) => (item.id != id ? item : { ...item, checkStatus: 'needed' }))
                                );
                                updateContact({ id, checkStatus: 'needed' }).then(reloadData);
                              }
                            }}
                          >
                            <Iconify
                              color={checkStatus == 'needed' ? theme.palette.success.dark : undefined}
                              icon={checkStatus == 'needed' ? 'eva:person-done-fill' : 'eva:person-done-outline'}
                            />
                          </IconButton>
                          <IconButton
                            size="small"
                            color="inherit"
                            onClick={() => {
                              if (checkStatus != 'unneeded') {
                                setListUsers(
                                  listUsers.map((item) => (item.id != id ? item : { ...item, checkStatus: 'unneeded' }))
                                );
                                updateContact({ id, checkStatus: 'unneeded' }).then(reloadData);
                              }
                            }}
                          >
                            <Iconify
                              color={checkStatus == 'unneeded' ? theme.palette.error.dark : undefined}
                              icon={checkStatus == 'unneeded' ? 'eva:person-delete-fill' : 'eva:person-delete-outline'}
                            />
                          </IconButton>
                          {phoneNumber ? (
                            <>
                              <IconButton
                                size="small"
                                color="inherit"
                                onClick={() => {
                                  location.href = `tel:${phoneNumber}`;
                                }}
                              >
                                <Iconify icon={'eva:phone-call-outline'} />
                              </IconButton>
                              <IconButton
                                size="small"
                                color="inherit"
                                onClick={() => {
                                  window.open(
                                    `https://zalo.me/${phoneNumber}`,
                                    '_blank' // <- This is what makes it open in a new window.
                                  );
                                }}
                              >
                                <Iconify icon={'eva:link-2-outline'} />
                              </IconButton>
                              {/* <IconButton
                                size="small"
                                color="inherit"
                                onClick={() => {
                                  addLead({ phoneNumber }).then(reloadData);
                                }}
                              >
                                <Iconify icon={'eva:person-add-outline'} />
                              </IconButton> */}
                            </>
                          ) : null}
                          {window.location.href.includes('noithat') ? (
                            <IconButton
                              size="small"
                              color="inherit"
                              onClick={() => {
                                window.open(
                                  `https://business.facebook.com/latest/inbox/messenger?asset_id=2322923711073637&bpn_id=404158850392224&nav_ref=manage_page_ap_plus_inbox_message_button&mailbox_id=&selected_item_id=${facebookId}`,
                                  '_blank' // <- This is what makes it open in a new window.
                                );
                              }}
                            >
                              <Iconify icon={'eva:message-circle-outline'} />
                            </IconButton>
                          ) : null}
                          <IconButton
                            size="small"
                            color="inherit"
                            onClick={() => {
                              setListUsers(listUsers.filter((item) => item.id != id));
                              updateContact({ id, ignore: true }).then(reloadData);
                            }}
                          >
                            <Iconify icon={'eva:close-circle-outline'} />
                          </IconButton>
                        </TableCell>
                        <TableCell
                          align="left"
                          width={160}
                          sx={{
                            px: 0.5,
                            py: 2,
                          }}
                        >
                          <Link href={facebookUrl} target="_blank">
                            <Typography variant="subtitle2" noWrap>
                              {displayName}
                            </Typography>
                            <Typography variant="subtitle3" noWrap>
                              {moment(createDate).fromNow()}
                            </Typography>
                          </Link>
                          <br />
                          <Button
                            size="small"
                            variant="text"
                            onClick={() => {
                              setNoteView(row);
                            }}
                            color={checkBy?.fullName ? 'success' : 'warning'}
                            sx={{ color: checkBy?.fullName ? 'success.dark' : 'warning.dark', marginLeft: '-5px' }}
                            aria-label={checkBy?.fullName ? `${checkBy?.fullName} đã xử lý` : 'Chưa xử lý'}
                            startIcon={<Iconify icon={'eva:clipboard-outline'} />}
                          >
                            {checkBy?.fullName ? `${checkBy?.fullName} đã xử lý` : 'Chưa xử lý'}
                          </Button>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            px: 0.5,
                            py: 2,
                          }}
                        >
                          <Link href={postUrl} target="_blank">
                            <Typography variant="subtitle2" color={theme.palette[postContent ? 'info' : 'error'].dark}>
                              {postContent ? 'Bài viết' : 'Bình luận'}
                            </Typography>
                          </Link>
                          {postContent || commentContent}
                        </TableCell>
                        <TableCell
                          align="right"
                          width={140}
                          sx={{
                            pl: 0.5,
                            pr: 2,
                            py: 2,
                          }}
                        >
                          <Link href={`tel:${phoneNumber}`}>{phoneNumber}</Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={usersFiltered.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Dialog
        onClose={() => {
          setNoteView();
        }}
        open={noteView}
      >
        <DialogTitle>
          {noteView?.displayName} - {noteView?.phoneNumber || 'Không có SĐT'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Nhập ghi chú, lịch sử thao tác hoặc bất cứ điều gì bạn muốn!</DialogContentText>
          <TextField
            inputRef={(r) => (input.current = r)}
            autoFocus
            multiline
            margin="dense"
            label="Nhập nội dung..."
            type="text-area"
            fullWidth
            variant="standard"
          />
          <Button
            sx={{
              boxShadow: 0,
            }}
            style={{
              marginTop: '10px',
              marginBottom: '24px',
            }}
            onClick={handleAddActivity}
            variant="contained"
          >
            Thêm
          </Button>
          <AppOrderTimeline sx={{ boxShadow: 0, padding: 0, margin: '-24px' }} title="Hoạt động" list={activities} />
        </DialogContent>
      </Dialog>
    </>
  );
}
