
import ExcelJS from "exceljs";
import FileSaver from "file-saver";

export const exportUsers = async (users, fileName) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('icontacts.net');
  sheet.addRow(['ID facebook','Tên', 'SĐT']);
  users.forEach(user => {
    sheet.addRow([user.facebookId, user.displayName, user.phoneNumber]);
  });
  const buffer = await workbook.xlsx.writeBuffer();
  FileSaver.saveAs(new Blob([buffer]), fileName || "icontacts.xlsx");
};