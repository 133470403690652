import PropTypes from 'prop-types';
import { format } from 'date-fns';
// @mui
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Collapse from '@mui/material/Collapse';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// utils
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ORDER_STATUS_OPTIONS } from 'src/_mock';
import { useMemo } from 'react';
import moment from 'moment';
import { OutlinedInput, Select, Tooltip } from '@mui/material';
import { isEmpty } from 'lodash';
import { updateUser, updateAgency } from 'src/services/user';

// ----------------------------------------------------------------------

export default function OrderTableRow({
  row,
  selected,
  onDeleteRow,
  onSelectRow,
  onRestart,
  onCreateUser,
  onUpdateUser,
  reloadData,
}) {
  const {
    users: items = [],
    status,
    expiredAt,
    proxyStatus,
    lastRunning,
    lastStart,
    agency,
    cookieStatus,
    group = [],
    cookie,
    proxy,
    _id,
  } = row;
  const statusData = useMemo(() => {
    return ORDER_STATUS_OPTIONS.find((item) => item?.value === status);
  }, [status]);

  const isCookieOK = cookieStatus !== 'expired';
  const isProxyOK = proxyStatus !== 'expired';

  const confirm = useBoolean();

  const collapse = useBoolean();

  const popover = usePopover();

  const lastRunningStatus = useMemo(() => {
    let runningStatus = 'running';
    const runTime = moment(lastRunning);
    const diff = moment().diff(runTime, 'minutes');
    console.log(diff);
    if (diff <= 60 && diff >= 30) {
      runningStatus = 'almost-died';
    }
    if (diff > 60) {
      runningStatus = 'died';
    }
    return runningStatus;
  }, [lastRunning]);
  const lastStartStatus = useMemo(() => {
    let runningStatus = 'running';
    const runTime = moment(lastStart);
    const diff = moment().diff(runTime, 'minutes');
    console.log(diff);
    if (diff <= 60 && diff >= 30) {
      runningStatus = 'almost-died';
    }
    if (diff > 60) {
      runningStatus = 'died';
    }
    return runningStatus;
  }, [lastStart]);

  const renderPrimary = (
    <TableRow hover selected={selected}>
      <TableCell>
        <ListItemText
          primary={
            <Label
              variant="soft"
              color={
                (lastRunningStatus === 'running' && 'success') ||
                (lastRunningStatus === 'almost-died' && 'warning') ||
                (lastRunningStatus === 'died' && 'error') ||
                'default'
              }
            >
              {moment(lastRunning).fromNow(true)}
            </Label>
          }
          secondary={
            <Label
              variant="soft"
              color={
                (lastStartStatus === 'running' && 'success') ||
                (lastStartStatus === 'almost-died' && 'warning') ||
                (lastStartStatus === 'died' && 'error') ||
                'default'
              }
            >
              {moment(lastStart).fromNow(true)}
            </Label>
          }
          primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
          }}
        />
      </TableCell>

      <TableCell sx={{ display: 'flex', alignItems: 'center', height: 80.5 }}>
        <Avatar alt={agency} sx={{ mr: 2 }} />

        <ListItemText
          primary={agency.toUpperCase()}
          secondary={`${group.length} nhóm`}
          primaryTypographyProps={{ typography: 'body2' }}
          secondaryTypographyProps={{
            component: 'span',
            color: 'text.disabled',
          }}
        />
      </TableCell>
      <TableCell>
        <Tooltip title={statusData?.label || status}>
          <Label
            variant="soft"
            color={
              (status === 'running' && 'success') ||
              (status === 'almost-expired' && 'warning') ||
              (status === 'expired' && 'error') ||
              'default'
            }
          >
            {format(new Date(expiredAt || 0), 'dd MMM yyyy')}
          </Label>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Label
          variant="soft"
          color={isCookieOK ? 'success' : 'error'}
          onClick={async () => {
            await updateAgency({
              id: _id,
              data: {
                cookieStatus: !isCookieOK ? 'running' : 'expired',
              },
            }).catch(console.log);
            reloadData();
          }}
        >
          {isCookieOK ? (!isEmpty(cookie) ? 'Đã gắn' : 'Chưa có') : 'FAILED'}
        </Label>
      </TableCell>
      <TableCell>
        <Label
          variant="soft"
          color={isProxyOK ? 'success' : 'error'}
          onClick={async () => {
            await updateAgency({
              id: _id,
              data: {
                proxyStatus: !isProxyOK ? 'running' : 'expired',
              },
            }).catch(console.log);
            reloadData();
          }}
        >
          {isProxyOK ? proxy?.split(':')?.[0] || 'Chưa có' : 'FAILED'}
        </Label>
      </TableCell>

      <TableCell align="center"> {items?.length} </TableCell>

      <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
        <IconButton
          color={collapse.value ? 'inherit' : 'default'}
          onClick={collapse.onToggle}
          sx={{
            ...(collapse.value && {
              bgcolor: 'action.hover',
            }),
          }}
        >
          <Iconify icon="eva:arrow-ios-downward-fill" />
        </IconButton>

        <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      </TableCell>
    </TableRow>
  );

  const renderSecondary = (
    <TableRow>
      <TableCell sx={{ p: 0, border: 'none' }} colSpan={8}>
        <Collapse in={collapse.value} timeout="auto" unmountOnExit sx={{ bgcolor: 'background.neutral' }}>
          <Stack component={Paper} sx={{ m: 1 }}>
            {items.map((item) => (
              <Stack
                key={item.id}
                direction="row"
                alignItems="center"
                sx={{
                  px: 1.5,
                  '&:not(:last-of-type)': {
                    borderBottom: (theme) => `solid 2px ${theme.palette.background.neutral}`,
                  },
                }}
              >
                <Avatar src={item.coverUrl} variant="circular" sx={{ mr: 2 }} />

                <ListItemText
                  primary={item.fullName}
                  secondary={item.emailAddress}
                  primaryTypographyProps={{
                    typography: 'body2',
                  }}
                  secondaryTypographyProps={{
                    component: 'span',
                    color: 'text.disabled',
                    mt: 0.5,
                  }}
                />

                <Box>Ngày tạo: {format(new Date(item.createdAt || 0), 'dd MMM yyyy')}</Box>

                <Box>
                  <Stack direction={'row'} spacing={1} marginLeft={1}>
                    <Select
                      size="small"
                      value={item.isSuperAdmin ? 'admin' : 'employee'}
                      onChange={async () => {
                        await updateUser({
                          id: item._id,
                          data: {
                            isSuperAdmin: !item.isSuperAdmin,
                          },
                        }).catch(console.log);
                        reloadData();
                      }}
                      input={<OutlinedInput placeholder="Phân quyền" />}
                      sx={{ textTransform: 'capitalize', width: 130 }}
                    >
                      <MenuItem value={'admin'}>Admin</MenuItem>
                      <MenuItem value={'employee'}>Nhân viên</MenuItem>
                    </Select>
                    <Button
                      size="small"
                      onClick={async () => {
                        await updateUser({
                          id: item._id,
                          data: {
                            emailStatus: item.emailStatus === 'confirmed' ? 'unconfirmed' : 'confirmed',
                          },
                        }).catch(console.log);
                        reloadData();
                      }}
                      color={item.emailStatus !== 'confirmed' ? 'success' : 'error'}
                      variant="outlined"
                    >
                      {item.emailStatus !== 'confirmed' ? 'Mở khoá' : 'Khoá'}
                    </Button>
                    <Button onClick={() => onUpdateUser(item)} size="small" color="primary" variant="contained">
                      Sửa
                    </Button>
                  </Stack>
                </Box>
              </Stack>
            ))}
          </Stack>
        </Collapse>
      </TableCell>
    </TableRow>
  );

  return (
    <>
      {renderPrimary}

      {renderSecondary}

      <CustomPopover open={popover.open} onClose={popover.onClose} arrow="right-top" sx={{ width: 140 }}>
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" sx={{ mr: 1 }} />
          Xoá
        </MenuItem>
        <MenuItem
          onClick={() => {
            onRestart();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:restart-circle-bold" sx={{ mr: 1 }} />
          Khởi động lại
        </MenuItem>
        <MenuItem
          onClick={() => {
            onSelectRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" sx={{ mr: 1 }} />
          Sửa
        </MenuItem>
        <MenuItem
          onClick={() => {
            onCreateUser();
            popover.onClose();
          }}
        >
          <Iconify icon="eva:plus-circle-fill" sx={{ mr: 1 }} />
          Thêm nhân viên
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Xoá"
        content="Bạn có chắc chắn muốn xoá?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Xoá
          </Button>
        }
      />
    </>
  );
}

OrderTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
