import httpCommon from './http-common';

export const me = () => {
  return httpCommon.get('account/me');
};

export const users = (params) => {
  return httpCommon.get('facebook/users' + (params || ''));
};
export const updateContact = (data) => {
  return httpCommon.post('facebook/update-contact', data);
};

export const addContact = (data) => {
  return httpCommon.put('facebook/add-contact', data);
};

export const addLead = (data) => {
  return httpCommon.put('facebook/add-lead', data);
};

export const getActivity = (params) => {
  return httpCommon.get('activity/list' + (params || ''));
};

export const createActivity = (data) => {
  return httpCommon.post('activity/create', data);
};
export const agency = () => {
  return httpCommon.get('list-agency');
};

export const updateAgency = (data) => {
  return httpCommon.post('update-agency', data);
};

export const createAgency = (data) => {
  return httpCommon.post('create-agency', data);
};

export const sendMessage = (data) => {
  return httpCommon.post('send-message', data);
};

export const updateUser = (data) => {
  return httpCommon.post('update-user', data);
};

export const createUser = (data) => {
  return httpCommon.post('create-user', data);
};

