import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Link, Stack, TextField, Snackbar, Alert } from '@mui/material';
// components
import Iconify from '../../../components/iconify';
import { login } from 'src/services/auth';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [emailAddress, setUser] = useState('');
  const [password, setPass] = useState('');
  const [err, setErr] = useState('');

  const handleClick = async () => {
    login({
      emailAddress,
      password,
    })
      .then((res) => {
        localStorage.setItem('token', res.data.token);
        navigate('/dashboard', { replace: true });
      })
      .catch((err) => {
        setErr('Sai tên đăng nhập hoặc mật khẩu');
      });
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" onChange={(e) => setUser(e.target.value)} label="Tên đăng nhập" />
        <TextField
          name="password"
          label="Mật khẩu"
          onChange={(e) => setPass(e.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {/* <Checkbox name="remember" label="Remember me" /> */}
        <Link variant="subtitle2" underline="hover">
          Quên mật khẩu?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Đăng nhập
      </LoadingButton>
      <Snackbar open={err} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={6000}>
        <Alert severity="error" sx={{ width: '100%' }}>
          {err}
        </Alert>
      </Snackbar>
    </>
  );
}
