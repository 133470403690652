import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { useSnackbar } from 'src/components/snackbar';
import { updateAgency, createAgency } from 'src/services/user';

// ----------------------------------------------------------------------

export default function AgentQuickEditForm({ currentUser, open, onClose }) {
  const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({
    agency: Yup.string().required('Nhập tên'),
    expiredAt: Yup.number().required('Nhập ngày hết hạn'),
    cookie: Yup.string(),
    group: Yup.string(),
    proxy: Yup.string(),
  });
  const defaultValues = useMemo(
    () => ({
      agency: currentUser?.agency || '',
      cookie: currentUser?.cookie || '',
      group: currentUser?.group || '',
      proxy: currentUser?.proxy || '',
      expiredAt: currentUser?.expiredAt || new Date().getTime(),
    }),
    [currentUser]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    setValue('agency', currentUser?.agency || '');
    setValue('cookie', currentUser.cookie ? JSON.stringify(currentUser.cookie, null, 2) : '');
    setValue('group', (currentUser?.group || []).join('\n'));
    setValue('proxy', currentUser?.proxy || '');
    setValue('expiredAt', currentUser?.expiredAt || new Date().getTime());
  }, [currentUser]);
  const isUpdate = !!currentUser._id;
  const onSubmit = handleSubmit(async (data) => {
    try {
      console.log(data);
      const submitData = {
        ...data,
        agency: data.agency.toLowerCase(),
        cookie: data.cookie ? JSON.parse(data.cookie) : undefined,
        group: data.group ? data.group.split('\n').map((item) => item.trim()) : undefined,
      };
      if (isUpdate) {
        const ok = await updateAgency({
          id: currentUser._id,
          data: submitData,
        });
      } else {
        const ok = await createAgency({
          data: submitData,
        });
      }
      onClose();
      enqueueSnackbar(isUpdate ? 'Tạo thành công!' : 'Thêm thành công!');
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        error.message.includes('Unexpected end of JSON input')
          ? 'Cookie không hợp lệ'
          : isUpdate
          ? 'Tạo thất bại'
          : 'Không thể cập nhật',
        { variant: 'error' }
      );
    }
  });

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>{isUpdate ? 'Cập nhật' : 'Thêm'} đối tác</DialogTitle>
        <DialogContent>
          <Stack spacing={2} pt={1}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(3, 1fr)',
              }}
            >
              <RHFTextField size="small" name="agency" label="Tên" />
              <RHFTextField size="small" name="proxy" label="Proxy" />
              <Controller
                name="expiredAt"
                control={methods.control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    onChange={(value) => {
                      field.onChange(value.getTime());
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        size: 'small',
                      },
                    }}
                    name="expiredAt"
                    label="Ngày hết hạn"
                  />
                )}
              />
            </Box>
            <RHFTextField maxRows={5} size="small" name="group" multiline label="Nhóm" />
            <RHFTextField maxRows={5} size="small" name="cookie" multiline label="Cookie" />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Stack spacing={2} px={2} pb={2} direction={'row'}>
            <Button variant="outlined" onClick={onClose}>
              Huỷ
            </Button>

            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              {isUpdate ? 'Cập nhật' : 'Thêm'}
            </LoadingButton>
          </Stack>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

AgentQuickEditForm.propTypes = {
  currentUser: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
