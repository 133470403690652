import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import { MenuItem, OutlinedInput, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
import { useSnackbar } from 'src/components/snackbar';
import { createUser, updateUser } from 'src/services/user';

// ----------------------------------------------------------------------

export default function UserQuickEditForm({ currentUser, open, onClose }) {
  const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({
    fullName: Yup.string().required('Nhập tên'),
    emailAddress: Yup.string().required('Nhập tên đăng nhập/Email'),
    password: Yup.string(),
    isSuperAdmin: Yup.string(),
    agency: Yup.string(),
  });
  const defaultValues = useMemo(
    () => ({
      fullName: currentUser?.fullName || '',
      emailAddress: currentUser?.emailAddress || '',
      password: currentUser?.password || '',
      isSuperAdmin: currentUser?.isSuperAdmin ? 'admin' : 'employee',
      agency: currentUser?.agency || '',
    }),
    [currentUser]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    setValue('fullName', currentUser?.fullName || '');
    setValue('emailAddress', currentUser.emailAddress || '');
    setValue('isSuperAdmin', currentUser?.isSuperAdmin ? 'admin' : 'employee');
    setValue('agency', currentUser?.agency || '');
    setValue('password', '');
  }, [currentUser]);
  const isUpdate = !!currentUser._id;

  const onSubmit = handleSubmit(async (data) => {
    try {
      console.log(data);
      const submitData = {
        ...data,
        isSuperAdmin: data.isSuperAdmin === 'admin',
        agency: currentUser?.agency,
      };
      if (isUpdate) {
        const ok = await updateUser({
          id: currentUser._id,
          data: submitData,
        });
      } else {
        const ok = await createUser({
          data: { ...submitData, emailStatus: 'confirmed' },
        });
      }
      onClose();
      enqueueSnackbar(isUpdate ? 'Tạo thành công!' : 'Thêm thành công!');
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        error.message.includes('Unexpected end of JSON input')
          ? 'Cookie không hợp lệ'
          : !isUpdate
          ? 'Tạo thất bại'
          : 'Không thể cập nhật',
        { variant: 'error' }
      );
    }
  });

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 'sm' },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>{isUpdate ? 'Cập nhật' : 'Thêm'} nhân viên</DialogTitle>
        <DialogContent>
          <Stack spacing={2} pt={1}>
            <Box
              rowGap={2}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField size="small" name="fullName" label="Tên" />
              <RHFTextField size="small" name="emailAddress" label="Tên đăng nhập/Email" />
              <RHFSelect
                size="small"
                name={'isSuperAdmin'}
                fullWidth
                placeholder="Phân quyền"
                label="Phân quyền"
                input={<OutlinedInput fullWidth placeholder="Phân quyền" />}
                sx={{ textTransform: 'capitalize' }}
              >
                <MenuItem value={'admin'}>Admin</MenuItem>
                <MenuItem value={'employee'}>Nhân viên</MenuItem>
              </RHFSelect>
              <RHFTextField maxRows={5} size="small" name="password" multiline label="Mật khẩu" />
            </Box>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Stack spacing={2} px={2} pb={2} direction={'row'}>
            <Button variant="outlined" onClick={onClose}>
              Huỷ
            </Button>

            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              {isUpdate ? 'Cập nhật' : 'Thêm'}
            </LoadingButton>
          </Stack>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

UserQuickEditForm.propTypes = {
  currentUser: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
