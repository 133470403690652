import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { report } from 'src/services/dashboard';
import { getActivity } from 'src/services/user';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();

  const [reportData, setReportData] = useState(null);
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    Promise.all([getActivity(), report()]).then(([activity, report]) => {
      setActivities(
        activity?.data.map(({ id, content, createDate, contentType }) => ({
          id,
          title: content,
          type: contentType,
          time: createDate,
        }))
      );
      setReportData(report.data);
    });
  }, []);
  return (
    <>
      <Helmet>
        <title> Dashboard | iContacts </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Xin chào, rất vui vì bạn đã trở lại!
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              loading={!reportData}
              title="Tổng số khách hàng"
              total={reportData?.all || 0}
              color="success"
              icon={'eva:people-outline'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              loading={!reportData}
              title="Khách hàng đã xử lý"
              total={reportData?.checkin || 0}
              color="primary"
              icon={'eva:person-done-outline'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              loading={!reportData}
              title="Khách hàng chưa xử lý"
              total={(reportData?.all || 0) - (reportData?.checkin || 0)}
              color="error"
              icon={'eva:person-delete-outline'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              loading={!reportData}
              title="Khách hàng có số điện thoại"
              total={reportData?.hasPhone || 0}
              color="warning"
              icon={'eva:person-add-outline'}
            />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              loading={!reportData}
              title="Nhu cầu của khách hàng"
              subheader="Nhu cầu khách hàng 10 ngày gần đây"
              chartLabels={[
                '01/01/2023',
                '02/01/2023',
                '03/01/2023',
                '04/01/2023',
                '05/01/2023',
                '06/01/2023',
                '07/01/2023',
                '08/01/2023',
                '09/01/2023',
                '10/01/2023',
                '11/01/2023',
              ]}
              chartData={[
                {
                  name: 'Có nhu cầu',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: 'Không có nhu cầu',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
              ]}
            />
          </Grid> */}
          <Grid item xs={12} md={6} lg={8}>
            <AppOrderTimeline
              loading={!reportData}
              title="Hoạt động gần đây"
              list={activities}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Nguồn khách hàng"
              loading={!reportData}
              chartData={[
                { label: 'Bình luận', value: reportData?.totalComment || 0 },
                { label: 'Bài viết', value: reportData?.totalPost || 0 },
              ]}
              chartColors={[theme.palette.primary.main, theme.palette.success.main]}
            />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Khách đã xử lý"
              subheader="Khách hàng đã xử lý trong 10 ngày gần đây"
              chartData={[
                { label: 'Tuấn', value: 40000000 },
                { label: 'Thành', value: 43000000 },
                { label: 'Trang', value: 44800000 },
                { label: 'Nhung', value: 47000000 },
                { label: 'Khánh', value: 54000000 },
                { label: 'Huy', value: 58000000 },
                { label: 'Mai', value: 69000000 },
                { label: 'Thảo', value: 110000000 },
                { label: 'Phước', value: 120000000 },
                { label: 'Bình', value: 143600000 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Hoạt động gần đây"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  'Khách hàng đã tạm ứng 1,200,000đ mã giao dịch #21270',
                  '12 đơn hàng đã thanh toán',
                  'Đã tạo đơn hàng #37745',
                  'Khách hàng đã tạo lịch hẹn #37742',
                  'Đơn hàng #37746 đã bị huỷ',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.recent(),
              }))}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
}
