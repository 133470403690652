import { useCallback, useEffect, useState } from 'react';
// @mui
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import { alpha } from '@mui/material/styles';
// routes
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
// _mock
import { ORDER_STATUS_OPTIONS } from 'src/_mock';
// utils
import { fTimestamp } from 'src/utils/format-time';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { ConfirmDialog } from 'src/components/custom-dialog';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  emptyRows,
  getComparator,
  useTable,
} from 'src/components/table';
//
import { agency, sendMessage } from 'src/services/user';
import OrderTableFiltersResult from '../order-table-filters-result';
import OrderTableRow from '../order-table-row';
import OrderTableToolbar from '../order-table-toolbar';
import AgentQuickEditForm from '../agent-quick-edit-form';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import UserQuickEditForm from '../user-quick-edit-form';

// ----------------------------------------------------------------------

const STATUS_OPTIONS = [{ value: 'all', label: 'Tất cả' }, ...ORDER_STATUS_OPTIONS];

const TABLE_HEAD = [
  { id: 'lastRunning', label: 'Lần cuối', width: 116 },
  { id: 'agency', label: 'Khách hàng' },
  { id: 'expiredAt', label: 'Gia hạn', width: 140 },
  { id: 'cookie', label: 'Cookie', width: 80 },
  { id: 'proxy', label: 'Proxy', width: 80 },
  { id: 'users', label: 'Users', width: 50, align: 'center' },
  { id: '', width: 88 },
];

const defaultFilters = {
  name: '',
  status: 'all',
  startDate: null,
  endDate: null,
};

// ----------------------------------------------------------------------

const mapData = (item) => {
  let status = 'running';
  const expiredAt = moment(item.expiredAt);
  if (expiredAt.diff(moment(), 'days') <= 5) {
    status = 'almost-expired';
  }
  if (expiredAt.diff(moment()) < 0) {
    status = 'expired';
  }
  return {
    ...item,
    status,
  };
};

export default function OrderListView() {
  const [tableData, setTableData] = useState([]);
  const [row, setRow] = useState({});
  const [user, setUser] = useState({});
  const quickAgentEdit = useBoolean();
  const quickUserEdit = useBoolean();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    reloadData();
  }, []);

  const reloadData = () => {
    agency().then((res) => {
      setTableData((res?.data || []).map(mapData));
    });
  };
  const table = useTable({ defaultDense: true });

  const settings = useSettingsContext();

  const router = useRouter();

  const confirm = useBoolean();
  useEffect(() => {
    reloadData();
    const interval = setInterval(() => {
      reloadData();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const [filters, setFilters] = useState(defaultFilters);

  const dateError =
    filters.startDate && filters.endDate ? filters.startDate.getTime() > filters.endDate.getTime() : false;

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
    dateError,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !!filters.name || filters.status !== 'all' || (!!filters.startDate && !!filters.endDate);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData.filter((row) => row.id !== id);
      setTableData(deleteRow);

      table.onUpdatePageDeleteRow(dataInPage.length);
    },
    [dataInPage.length, table, tableData]
  );

  const handleDeleteRows = useCallback(() => {
    const deleteRows = tableData.filter((row) => !table.selected.includes(row.id));
    setTableData(deleteRows);

    table.onUpdatePageDeleteRows({
      totalRows: tableData.length,
      totalRowsInPage: dataInPage.length,
      totalRowsFiltered: dataFiltered.length,
    });
  }, [dataFiltered.length, dataInPage.length, table, tableData]);

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const handleViewRow = useCallback(
    (id) => {
      router.push(paths.dashboard.order.details(id));
    },
    [router]
  );

  const handleFilterStatus = useCallback(
    (event, newValue) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Đối tác"
          links={[
            {
              name: 'Dashboard',
              href: '/dashboard',
            },
            { name: 'Đối tác' },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card>
          <Tabs
            value={filters.status}
            onChange={handleFilterStatus}
            sx={{
              boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab
                key={tab.value}
                iconPosition="end"
                value={tab.value}
                label={tab.label}
                sx={{
                  boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                  px: 2,
                  py: 0,
                  mr: '0 !important',
                  minHeight: 50,
                }}
                icon={
                  <Label
                    variant={((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'}
                    color={
                      (tab.value === 'running' && 'success') ||
                      (tab.value === 'almost-expired' && 'warning') ||
                      (tab.value === 'expired' && 'error') ||
                      'default'
                    }
                  >
                    {tab.value === 'all'
                      ? tableData.length
                      : tableData.filter((order) => order.status === tab.value).length}
                  </Label>
                }
              />
            ))}
          </Tabs>

          <OrderTableToolbar
            filters={filters}
            onFilters={handleFilters}
            onCreate={quickAgentEdit.onTrue}
            canReset={canReset}
            onResetFilters={handleResetFilters}
          />

          {canReset && (
            <OrderTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              //
              onResetFilters={handleResetFilters}
              //
              results={dataFiltered.length}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) =>
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
              action={
                <Tooltip title="Xoá">
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                  onSelectAllRows={(checked) =>
                    table.onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {dataFiltered
                    .slice(table.page * table.rowsPerPage, table.page * table.rowsPerPage + table.rowsPerPage)
                    .map((row) => (
                      <OrderTableRow
                        key={row.id}
                        row={row}
                        reloadData={reloadData}
                        onCreateUser={() => {
                          setUser({
                            agency: row.agency,
                          });
                          quickUserEdit.onTrue();
                        }}
                        onSelectRow={() => {
                          setRow(row);
                          quickAgentEdit.onTrue();
                        }}
                        onUpdateUser={(item) => {
                          console.log(item);
                          setUser(item);
                          quickUserEdit.onTrue();
                        }}
                        onRestart={async () => {
                          try {
                            await sendMessage({ data: { action: 'restart', agency: row.agency } });
                            enqueueSnackbar('Đã gửi lệnh');
                          } catch (error) {
                            enqueueSnackbar('Gửi lệnh thất bại');
                          }
                        }}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={dataFiltered.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense
          />
        </Card>
      </Container>
      <AgentQuickEditForm
        currentUser={row}
        open={quickAgentEdit.value}
        onClose={() => {
          quickAgentEdit.onFalse();
          reloadData();
        }}
      />
      <UserQuickEditForm
        currentUser={user}
        open={quickUserEdit.value}
        onClose={() => {
          quickUserEdit.onFalse();
          reloadData();
        }}
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filters, dateError }) {
  const { status, name, startDate, endDate } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (name) {
    inputData = inputData.filter(
      (order) =>
        order.agency.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
        order.proxy.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }

  if (status !== 'all') {
    inputData = inputData.filter((order) => order.status === status);
  }

  if (!dateError) {
    if (startDate && endDate) {
      inputData = inputData.filter(
        (order) =>
          fTimestamp(order.expiredAt) >= fTimestamp(startDate) && fTimestamp(order.expiredAt) <= fTimestamp(endDate)
      );
    }
  }

  return inputData;
}
