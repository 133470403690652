import PropTypes from 'prop-types';
// @mui
import { Button, FormControlLabel, FormGroup, InputAdornment, OutlinedInput, Switch, Toolbar } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// component
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  // height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: '15px !important',
  paddingRight: '15px !important',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));
const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: 15,
  },
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onFilterOption: PropTypes.func,
  hideToolbar: PropTypes.bool,
};

export default function UserListToolbar({
  numSelected,
  filterName,
  onFilterName,
  onFilterOption,
  exportExcel,
  hideToolbar,
}) {
  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      <StyledSearch
        value={filterName}
        onChange={onFilterName}
        placeholder="Tìm kiếm khách hàng..."
        size="small"
        startAdornment={
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
          </InputAdornment>
        }
      />
      {!hideToolbar ? (
        <StyledFormGroup row>
          <FormControlLabel
            control={
              <Switch
                size="small"
                onChange={(e) => {
                  onFilterOption({
                    phoneOnly: e.target.checked,
                  });
                }}
              />
            }
            label="Có SĐT"
          />
          <FormControlLabel
            control={
              <Switch
                size="small"
                onChange={(e) => {
                  onFilterOption({
                    post: e.target.checked,
                  });
                }}
              />
            }
            label="Bài viết"
          />
          <FormControlLabel
            control={
              <Switch
                size="small"
                onChange={(e) => {
                  onFilterOption({
                    comment: e.target.checked,
                  });
                }}
              />
            }
            label="Bình luận"
          />
          <Button onClick={exportExcel}>Xuất Excel</Button>
        </StyledFormGroup>
      ) : null}
    </StyledRoot>
  );
}
